
import axios from '@/common/js/request'
// 设备巡检-查看详情
export function dataInfo(params){  
    const dataInfo = axios({
        url: '/zypatrol/facility/equipment/dataInfo',
        method: 'get',
        params
    })
    return dataInfo
}
// 设备查看详情
export function dataInfoget(params){  
    const dataInfoget = axios({
        url: '/zypatrol/facility/equipment/get',
        method: 'get',
        params
    })
    return dataInfoget
}